<template>
  <div id="app" class="view">
    <!-- <router-view/> -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {
  },
  components: {
  },
  methods: {
  },
  created() {
  },
};
</script>

<style>
.view{
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
}
</style>
