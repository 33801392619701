// 这边是路由的路径
import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
export default new Router({
  mode: 'hash',
  routes: [
    // {
    // 　　path: '*',
    // 　　component: (resolve) => require(['./views/error404.vue'], resolve)
    // },
    {
      path: '/login/:type',
      component: (resolve) => require(['../pages/Login'], resolve),
    },
    {
      path: '/wxLogin/:appCode/:hash',
      component: (resolve) => require(['../pages/WxLogin'], resolve),
    },
    {
      path: '/register/:type',
      component: (resolve) => require(['../pages/Register'], resolve),
    },
    {
      path: '/bindEmail/:type',
      component: (resolve) => require(['../pages/BindEmail'], resolve),
    },
    {
      path: '/bindWx/:type/:appCode/:hash',
      component: (resolve) => require(['../pages/WxBind'], resolve),
    },
    {
      path: '/reset/:token',
      component: (resolve) => require(['../pages/ResetPassword'], resolve),
    },
    {
      path: '/send/:type',
      component: (resolve) => require(['../pages/RestpasswordBuyEamail'], resolve),
    },
    {
      path: '/verify/:type',
      component: (resolve) => require(['../pages/Verify'], resolve),
    },
    {
      path: '/home/:type',
      component: (resolve) => require(['../pages/Home'], resolve),
    },
    {
      path: '/userInfo/:type',
      component: (resolve) => require(['../pages/UserInfo'], resolve),
    },
    {
      path: '/agreement',
      component: (resolve) => require(['../pages/Agreement'], resolve),
    }
  ]
})